import React from 'react';

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';

import Beneficiarios from '../components/pages/Beneficiarios';

class BeneficiariosPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { pageContext } = this.props
        return (
            <LayoutsComponent
                broker={pageContext.broker}
                layout={pageContext.layout}
                companyName={pageContext.company}
                location={this.props.location}
                pageContext={this.props.pageContext}
                render={() => (
                    <>
                        <SEO title={pageContext.title} />
                        <Beneficiarios companyName={pageContext.company} />
                    </>
                )}
            />
        )
    }
}

export default BeneficiariosPage;
